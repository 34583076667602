.forecast-scenario-comparison {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.forcast-heading {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.filter-dropdown {
  margin-left: auto;
  margin-bottom: 10px;
}

.chart-title {
  font-size: 18px;
}

.chart-description {
  font-size: 16px;
  color: #666;
}

.chart-container {
  width: 730px;
  margin: auto;
}

.chart-heading-content {
  flex: 1;
  margin-right: 20px;
}

.chart-heading-controls {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.liquidity-tables-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
}
