table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:nth-child(odd) {
  background-color: #fff;
}

.chart-title-wrapper.minimized {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.red-highlight {
  color: red;
}

.table-title {
  font-size: 15px;
}

.table-title:hover {
  cursor: pointer;
  text-decoration: underline;
}
